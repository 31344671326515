*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  line-height: 1.15;
}

html,
body {
  min-height: 100%;
}

body {
  margin: 0;
  background-color: #f9fafc;
  font-family: helvetica;
  color: #0a166a;
}

h1,
h2,
h3,
h4,
p,
ul {
  margin-top: 0;
}

h1,
h2,
h3,
h4 {
  margin-bottom: 10px;
}

